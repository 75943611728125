<div class="container-fluid">
  <app-pagetitle title="Chat" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="d-lg-flex mb-4">
    <div class="chat-leftsidebar">
      <div class="p-3 border-bottom">
        <div class="d-flex">
          <div class="align-self-center me-3">
            <img src="assets/images/users/avatar-2.jpg" class="avatar-xs rounded-circle" alt="">
          </div>
          <div class="flex-1">
            <h5 class="font-size-15 mt-0 mb-1">Ricky Clark</h5>
            <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle me-1"></i> Active</p>
          </div>

          <div>
            <div class="dropdown chat-noti-dropdown" ngbDropdown>
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
                <i class="mdi mdi-dots-horizontal font-size-20"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom py-2">
        <div class="search-box chat-search-box">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search...">
            <i class="ri-search-line search-icon"></i>
          </div>
        </div>
      </div>

      <div class="chat-leftsidebar-nav">
        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-pills nav-justified">
          <li [ngbNavItem]="1">
            <a ngbNavLink>
              <i class="ri-message-2-line font-size-20"></i>
              <span class="mt-2 d-none d-sm-block">Chat</span>
            </a>
            <ng-template ngbNavContent>
              <div>
                <h5 class="font-size-14 px-3 mb-3">Recent</h5>
                <perfect-scrollbar style="position: relative; height: 345px;">
                  <ul class="list-unstyled chat-list">
                    <li *ngFor="let chat of chatData">
                      <a (click)="chatUsername(chat.name, chat.image, chat.status)">
                        <div class="d-flex">
                          <div class="user-img online align-self-center me-3" *ngIf="chat.image" [ngClass]="{'online': chat.status === 'online',
                          'away': chat.status === 'intermediate'}">
                            <img src="{{chat.image}}" class="rounded-circle avatar-xs" alt="">
                            <span class="user-status"></span>
                          </div>
                          <div class="user-img me-3" *ngIf="!chat.image" [ngClass]="{'online': chat.status === 'online',
                          'away': chat.status === 'intermediate'}">
                            <div class="avatar-xs align-self-center">
                              <span
                                class="avatar-title rounded-circle bg-light text-body">{{chat.name.charAt(0)}}</span>
                              <span class="user-status"></span>
                            </div>
                          </div>
                          <div class="flex-1 overflow-hidden">
                            <h5 class="text-truncate font-size-14 mb-1">{{chat.name}}</h5>
                            <p class="text-truncate mb-0">{{chat.message}}</p>
                          </div>
                          <div class="font-size-11">{{chat.time}}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>
              <i class="ri-group-line font-size-20"></i>
              <span class="mt-2 d-none d-sm-block">Group</span>
            </a>
            <ng-template ngbNavContent>
              <h5 class="font-size-14 px-3 mb-3">Group</h5>
              <ul class="list-unstyled chat-list" data-simplebar style="max-height: 345px;">
                <li>
                  <a href="#">
                    <div class="d-flex align-items-center">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-light text-body">
                          G
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-14 mb-0">General</h5>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <div class="d-flex align-items-center">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-light text-body">
                          R
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-14 mb-0">Reporting</h5>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <div class="d-flex align-items-center">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-light text-body">
                          M
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-14 mb-0">Meeting</h5>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div class="d-flex align-items-center">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-light text-body">
                          A
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-14 mb-0">Project A</h5>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div class="d-flex align-items-center">
                      <div class="avatar-xs me-3">
                        <span class="avatar-title rounded-circle bg-light text-body">
                          B
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-14 mb-0">Project B</h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </ng-template>

          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>
              <i class="ri-contacts-book-2-line font-size-20"></i>
              <span class="mt-2 d-none d-sm-block">Contacts</span>
            </a>
            <ng-template ngbNavContent>
              <h5 class="font-size-14 px-3 mb-3">Contact</h5>

              <perfect-scrollbar style="height: 345px;">
                <div>
                  <div class="p-3">
                    A
                  </div>

                  <ul class="list-unstyled chat-list">
                    <li>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Adam Miller</h5>
                      </a>
                    </li>

                    <li>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Alfonso Fisher</h5>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="mt-4">
                  <div class="p-3">
                    B
                  </div>

                  <ul class="list-unstyled chat-list">
                    <li>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Bonnie Harney</h5>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="mt-4">
                  <div class="p-3">
                    C
                  </div>

                  <ul class="list-unstyled chat-list">
                    <li>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Charles Brown</h5>
                      </a>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Carmella Jones</h5>
                      </a>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Carrie Williams</h5>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="mt-4">
                  <div class="p-3">
                    D
                  </div>

                  <ul class="list-unstyled chat-list">
                    <li>
                      <a href="#">
                        <h5 class="font-size-14 mb-0">Dolores Minter</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </perfect-scrollbar>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="py-4"></div>
      </div>
    </div>

    <div class="w-100 user-chat mt-4 mt-sm-0">
      <div class="p-3 px-lg-4 user-chat-border">
        <div class="row">
          <div class="col-md-4 col-6">
            <h5 class="font-size-15 mb-1 text-truncate">{{username}}</h5>
            <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle text-success align-middle me-1" [ngClass]="{'text-success': status === 'online',
                'text-warning': status === 'intermediate'}"></i>
              {{status}}</p>
          </div>
          <div class="col-md-8 col-6">
            <ul class="list-inline user-chat-nav text-end mb-0">
              <li class="list-inline-item d-inline-block d-sm-none">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end dropdown-menu-md" ngbDropdownMenu>
                    <form class="p-2">
                      <div class="search-box">
                        <div class="position-relative">
                          <input type="text" class="form-control rounded" placeholder="Search...">
                          <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li class="d-none d-sm-inline-block">
                <div class="search-box me-2">
                  <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Search...">
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </li>
              <li class="list-inline-item m-0 d-none d-sm-inline-block">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-cog"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="#">View Profile</a>
                    <a class="dropdown-item" href="#">Clear chat</a>
                    <a class="dropdown-item" href="#">Muted</a>
                    <a class="dropdown-item" href="#">Delete</a>
                  </div>
                </div>
              </li>

              <li class="list-inline-item">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="px-lg-2">
        <div class="chat-conversation p-3">
          <perfect-scrollbar style="height: 450px;">
            <ul class="list-unstyled mb-0 pr-3" *ngFor="let data of chatMessagesData"
              [ngClass]="{ 'right': data.align === 'right' }">
              <li *ngIf="!data.text">
                <div class="conversation-list">
                  <div *ngIf="data.image" class="chat-avatar">
                    <img src="{{data.image}}" alt="">
                  </div>
                  <div class="ctext-wrap">
                    <div class="conversation-name">{{data.name}}</div>
                    <div class="ctext-wrap-content">
                      <p class="mb-0">
                        {{data.message}}
                      </p>
                    </div>
                    <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle me-1"></i> {{data.time}}</p>
                  </div>
                </div>
              </li>
              <li *ngIf="data.text">
                <div class="chat-day-title">
                  <span class="title">{{data.text}}</span>
                </div>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="px-lg-3">
        <div class="p-3 chat-input-section">
          <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">

            <div class="col">
              <div class="position-relative">
                <input type="text" class="form-control chat-input" placeholder="Enter Message..."
                  formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                  <span *ngIf="form.message.errors.required">This value is required.</span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light"><span
                  class="d-none d-sm-inline-block me-2">Send</span> <i class="mdi mdi-send"></i></button>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
