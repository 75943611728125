import {Base} from "./Base";
import {Role} from "./Role";
import {Client} from "./Client";
import {RolesEnum} from "../enums/RolesEnum";

export class User extends Base {

    id: number | string;
    firstName: string;
    lastName: string;
    email: string;
    roles?: string;
    customer?: Client

    mapFromJson(object: IUser) {
        this.id = object.id
        this.firstName = object.firstName
        this.lastName = object.lastName
        this.email = object.email
        this.roles = object.roles
    }
}

export interface IUser {
    id: number | string;
    firstName: string;
    lastName: string;
    email: string;
    roles?: string;
}