import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { Observable, of, throwError } from "rxjs";
import { Login } from "../types/requests";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRoutes } from "../routes/api.routes";
import { TokenService } from "./token.service";
import { catchError, map, tap } from "rxjs/operators";
import { HttpResponse } from "../types/http-response.type";
import { AuthenticatedUserService } from "./authenticated-user.service";
import { IUser, User } from "../models/User";
import Swal from 'sweetalert2';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    redirectPath: string;
    user: User;
    authenticated: boolean

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private authenticatedUserService: AuthenticatedUserService,
    ) {}

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string, loginType: string) {
        
        const body = { email: email, password: password, loginType: loginType }

        return this.http.post<Login>(ApiRoutes.auth.login, body).pipe(
            map(response => {
                this.tokenService.set(response.accessToken)
                return true
            }),
            catchError((err) => 
                of(
                    Swal.fire({
                        title: 'Error',
                        text: err + ' Revisa tus credenciales',
                        icon: 'error'
                      })
                )
            )
        );
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return this.http.post(ApiRoutes.auth.password_reset, {
            'email': email
        }).pipe(map(response => {
            return true
        }),
        catchError((err) => 
            of(
                Swal.fire({
                    title: 'Error',
                    text: err + ' Revisa tus credenciales',
                    icon: 'error'
                  })
            )
        ));
    }

    changePassword(token: string, password: string) {
        return this.http.post(ApiRoutes.auth.change_password, {
            'token': token,
            'password' : password
        }).pipe(map(response => {
            return true
        }),
        catchError((err) => 
            of(
                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error'
                })
            )
        ));
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        return this.http.delete(ApiRoutes.auth.logout);
    }

    // authenticatedUser() {
    //     console.log("auth service")
    //     return  this.http.get(ApiRoutes.auth.authenticatedUser).pipe(
    //         map(response => {
    //             console.log("auth exitoso", response['email'])
    //             if (response['email']) {
    //                 console.log("must be true")
    //                 return response
    //             }else{
    //                 console.log("must be false")
    //                 return response
    //             }
    //         }),
    //         catchError((err) => 
    //             of(
    //                 console.log("must have error", err),
    //                 alert(err), 
    //             )
    //         )
    //     );
    // }

    authenticatedUser(): Observable<HttpResponse<IUser>> {
        
        return this.http.get<HttpResponse<IUser>>(ApiRoutes.auth.authenticatedUser).pipe(
            map((response) => {
                if (response) {
                   // this.authenticatedUserService.changeUserStatus(response.body)
                }

                return response
            }),
            catchError((err, caught) => {
                
                return caught
            })
        );
    }
}

