import { AppUrls } from 'src/app/core/routes/app.routes';
import { MenuItem } from './menu.model';


export const ADMINMENU: MenuItem[] = [
    //MENU MOSTRABLE
    {
        id: 1,
        label: 'MENU',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'ri-dashboard-line',
        link: AppUrls.home.path
    },
    {
        id: 3,
        label: 'Usuarios',
        icon: 'ri-user-line',
        link: AppUrls.users.path
    },
    {
        id: 4,
        label: 'Clientes',
        icon: 'ri-user-smile-fill',
        link: AppUrls.clients.path
    },
    {
        id: 5,
        label: 'Trabajos',
        icon: 'ri-article-line',
        link: AppUrls.works.path
    },
];