import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUrls } from './core/routes/app.routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Avalúos Ingenio';

  constructor(private router: Router) { }

  ngOnInit() {
    
    const currentRoute = this.router.routerState.snapshot.url;

    // if(currentRoute == ''){
    //   this.router.navigate([AppUrls.home.path]);
    // }
  }
}
