import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppUrls } from '../../../core/routes/app.routes'
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { LanguageService } from '../../../core/services/language.service';
import { environment } from '../../../../environments/environment';
import {TokenService} from "../../../core/services/token.service";
import { UsersService } from 'src/app/content/services/users.service';
import { ColorPickerService } from 'ngx-color-picker';
import { color } from 'echarts';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  
  //Theme
  isDarkMode: boolean = false;
  isLightModeOn: boolean = true;
  isDarkModeOn: boolean = false;

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  //userDetauls
  UserInitials: string = ''

  // tslint:disable-next-line: max-line-length
  constructor(
      @Inject(DOCUMENT) 
      private document: any, 
      private router: Router, 
      private authService: AuthenticationService, 
      private authFackservice: AuthfakeauthenticationService, 
      public languageService: LanguageService, 
      public cookiesService: CookieService,
      private tokenService: TokenService,
      private authuser: UsersService,
    ){}

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {

    this.getCurrentTheme();

    this.getCurrentUser();

    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  getCurrentUser(){
    return new Promise((resolve) => {
        this.authuser.userAuthenticated().subscribe(response => {
            this.UserInitials = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase(); 
        });
    });
}

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  goToResetPassword(){
    this.router.navigate([AppUrls.auth.ChangePassword]);
  }

  /**
   * Logout the user
   */
  logout() {
    this.tokenService.remove();
    this.router.navigate([AppUrls.auth.login]);
  }

  setThemeMode(){

    this.isDarkMode = !this.isDarkMode;

    //Si esta en darkMode
    if(this.isDarkMode){
      localStorage.setItem('isDarkMode', '1');
      this.isLightModeOn = false;
      document.body.setAttribute('data-bs-theme','dark')
    }else{
      localStorage.setItem('isDarkMode', '0');
      this.isDarkModeOn = false;
      document.body.setAttribute('data-bs-theme','light')
    }
  }

  getCurrentTheme(){

    let currentThemeStatus = localStorage.getItem('isDarkMode');
    
    if(currentThemeStatus == '1'){
      localStorage.setItem('isDarkMode', '1');
      this.isLightModeOn = false;
      document.body.setAttribute('data-bs-theme','dark')
      this.isDarkMode = true;
    }else{
      localStorage.setItem('isDarkMode', '0');
      this.isDarkModeOn = false;
      document.body.setAttribute('data-bs-theme','light')
      this.isDarkMode = false;
    }
  }
}
