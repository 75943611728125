import { AppUrls } from 'src/app/core/routes/app.routes';
import { MenuItem } from './menu.model';


export const MENU: MenuItem[] = [
    //MENU MOSTRABLE
    {
        id: 1,
        label: 'MENU',
        isTitle: true
    }
];