<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        {{currentYear}} © Avalúos Ingenio.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Powered by <a href="https://deadline.mx/" target="_blank">Deadline</a>
        </div>
      </div>
    </div>
  </div>
</footer>
