<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Example</h4>
          <div style="height: 300px;" leaflet [leafletOptions]="options">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Marker Example</h4>
          <div style="height: 300px;" leaflet [leafletOptions]="options2" [leafletLayers]="layers">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Custom Marker</h4>
          <div class="row mb-3">
            <form class="col">
              <button type="button" class="btn btn-primary" (click)="addMarker()">Add Marker</button>
              <button type="button" class="btn btn-primary ms-1" (click)="removeMarker()">Remove Marker</button>
            </form>
          </div>
          <!-- Map -->
          <div leaflet style="height: 300px;" [leafletOptions]="options11" [leafletLayers]="markers">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
