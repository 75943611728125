import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {IUser, User} from "../models/User";

import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpResponse} from "../../core/types/http-response.type";
import {ApiRoutes} from "../../core/routes/api.routes";
import {AuthUser} from "src/app/core/models/AuthenticatedUser";

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedUserService {

    constructor(
        private http: HttpClient
    ) { }

    private userSource = new BehaviorSubject<User>(new User());

    userObservable = this.userSource.asObservable();

    changeUserStatus(userObject: IUser) {
        let user = new User()
        user.mapFromJson(userObject)

        this.userSource.next(user);
    }

    getProfile(): Observable<AuthUser> {
        const endpoint = ApiRoutes.auth.authenticatedUser;

        return this.http.get<AuthUser>(endpoint);
    }  
}