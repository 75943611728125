import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import {TokenService} from "../services/token.service";
import {AuthenticatedUserService} from "../services/authenticated-user.service";
import { environment } from '../../../environments/environment';
import { AppUrls } from '../routes/app.routes';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {

    authenticated: boolean = false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authUserService: AuthenticatedUserService,
        private tokenService: TokenService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.authenticationService.redirectPath = state.url

        if (this.tokenService.loggedIn()) {
            
            return new Promise<boolean>((resolve) => {
                this.authenticationService.authenticatedUser().subscribe(res => {
                    if (!res) {
                        this.redirectToLogin();
                        resolve(false)
                    }

                    //this.authUserService.changeUserStatus(res.body);
                    resolve(true);
                }, error1 => {
                    this.redirectToLogin();
                    console.error("User not authenticated")                    
                });
            });
        }

        this.redirectToLogin()
        return false;
    }

    redirectToLogin(returnUrl?: string) {
        this.router.navigate([AppUrls.auth.loginClient], {queryParams: {returnUrl: returnUrl}})
    }
}
