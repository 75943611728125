<div class="container-fluid">
  <app-pagetitle title="Customers" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>
            <!--  -->
            <a href="javascript:void(0);" class="btn btn-success mb-2" (click)="openModal(content)">
              <i class="mdi mdi-plus me-2"></i> Add Customer
            </a>
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Search:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                    aria-controls="tickets-table" ></label></div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-centered datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th style="width: 20px;">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customercheck" />
                      <label class="form-check-label" for="customercheck">&nbsp;</label>
                    </div>
                  </th>
                  <th>Customer</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Wallet Balance</th>
                  <th>Joining Date</th>
                  <th style="width: 120px;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of customersData; let i= index">
                  <td>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customercheck{{i}}" />
                      <label class="form-check-label mb-0" for="customercheck{{i}}">&nbsp;</label>
                    </div>
                  </td>
                  <td>{{item.name}}</td>
                  <td>{{item.email}}</td>
                  <td>{{item.phone}}</td>
                  <td>{{item.balance}}</td>
                  <td>{{item.date}}</td>
                  <td>
                    <a href="javascript:void(0);" class="me-3 text-primary" v-b-tooltip.hover title="Edit">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Contacts model  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Add Contacts</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="validationform">

        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" id="name" placeholder="Enter name" formControlName="name"
            [ngClass]="{'is-invalid': submitted && form.name.errors}">
          <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">Name is mendatory.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email address</label>
          <input class="form-control" id="email" placeholder="Enter email" formControlName="email"
            [ngClass]="{'is-invalid': submitted && form.email.errors}">
          <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
            <span *ngIf="form.email.errors.required">Email address is mendatory.</span>
            <span *ngIf="form.email.errors.pattern">Please enter valid email.</span>
          </div>
        </div>

        <div class="form-group">
          <label>Phone </label>
          <input type="number" class="form-control" placeholder="Enter phone number" formControlName="phone"
            [ngClass]="{'is-invalid': submitted && form.phone.errors}" minlength="10">
          <div *ngIf="submitted && form.phone.errors" class="invalid-feedback">
            <span *ngIf="form.phone.errors.required">Phone is mendatory.</span>
            <span *ngIf="form.phone.errors.minlength">Phone number must be 10 characters.</span>
          </div>
        </div>

        <div class="form-group">
          <label>Balance </label>
          <input type="number" class="form-control" placeholder="Enter balance" formControlName="balance"
            [ngClass]="{'is-invalid': submitted && form.balance.errors}">
          <div *ngIf="submitted && form.balance.errors" class="invalid-feedback">
            <span *ngIf="form.balance.errors.required">Balance is mendatory.</span>
          </div>
        </div>

        <div class="form-group">
          <label>Date </label>
          <input class="form-control" placeholder="Enter date" formControlName="date"
            [ngClass]="{'is-invalid': submitted && form.date.errors}">
          <div *ngIf="submitted && form.date.errors" class="invalid-feedback">
            <span *ngIf="form.date.errors.required">Date is mendatory.</span>
          </div>
        </div>

        <div class="text-end">
          <button type="submit" class="btn btn-success">Save</button>
          <button type="button" class="btn btn-danger ms-1" (click)="modal('close click')">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
