import {Injectable} from '@angular/core';
import {ApiRoutes} from "../routes/api.routes";


@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private iss;

    static __get() {
        return localStorage.getItem('token');
    }

    constructor() {
        this.iss = {
            login: ApiRoutes.auth.login,
        };
    }

    handle(token) {
        this.set(token.access_token);
    }

    set(token) {
        localStorage.setItem('token', token);
    }

    get(): string {
        return localStorage.getItem('token');
    }


    remove() {
        localStorage.removeItem('token');
    }

    loggedIn() {
        return this.isValid();
    }

    private isValid() {
        const token = this.get();

        return token != null;
    }

    private payload(token) {
        return this.decode(token);
    }

    private decode(token) {
        return JSON.parse(atob(token));
    }
}
