import { EnrolledRoutes } from "./enrolled-routes"

export const AppRoutes = {
    home: {
        path: 'dashboard',
        roles: EnrolledRoutes.home
    },
    users: {
        path: 'usuarios',
        create: 'nuevo',
        update: 'actualizar/:id',
        roles: EnrolledRoutes.users
    },
    works: {
        path: 'trabajos',
        create: 'nuevo',
        details: 'detalles/:id',
        update: 'actualizar/:id',
        roles: EnrolledRoutes.users
    },
    clients: {
        path: 'clientes',
        create: 'nuevo',
        details: 'detalles/:id',
        update: 'actualizar/:id',
        roles: EnrolledRoutes.users
    }
}

export const AppUrls = {
    auth: {
        login: '/account/login',
        loginClient: '/account/login-client',
        loginValuador: '/account/login',
        PasswordRecovery: '/account/recovery-password',
        ChangePassword: '/account/change-password'
    },
    home: {
        path: '/dashboard'
    },
    users: {
        path: '/usuarios',        
        create: '/usuarios/nuevo',
        update: '/usuarios/actualizar/'
    },
    works: {
        path: '/trabajos',
        details: '/trabajos/detalles/',
        create: '/trabajos/nuevo',
        update: '/trabajos/actualizar/'
    },
    clients: {
        path: '/clientes',
        details: '/clientes/detalles/',
        create: '/clientes/nuevo',
        update: '/clientes/actualizar/'
    },
}