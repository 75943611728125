import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenService } from "../services/token.service";
import { ApiRoutes } from "../routes/api.routes";


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor(
        private tokenService: TokenService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = !this.tokenService.loggedIn() ? req : req.clone({headers: req.headers.set('Authorization', `Bearer ${this.tokenService.get()}`)});

        if (req.url == ApiRoutes.auth.logout) {
            this.tokenService.remove();
            location.reload();
        }

        return next.handle(req)
    }
}