<div class="container-fluid">
  <app-pagetitle title="Range Slider" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <!-- Row -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Range slider</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Default</h5>
                <ngx-slider [(value)]="defaultVal" [options]="options"></ngx-slider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Min-Max</h5>
                <ngx-slider [(value)]="value" [options]="options" [(highValue)]="highValue"></ngx-slider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with custom step value</h5>
                <ngx-slider [(value)]="custom" [options]="customOption"></ngx-slider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with floating point values</h5>
                <ngx-slider [(value)]="floatValue" [options]="floatingOptions"></ngx-slider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with custom display function</h5>
                <ngx-slider [(value)]="value1" [(highValue)]="maxVal" [options]="option1"></ngx-slider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with ticks</h5>
                <ngx-slider [(value)]="tickvalue" [(highValue)]="tickhighValue" [options]="tickoptions"></ngx-slider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with draggable range</h5>
                <ngx-slider [(value)]="draggableminValue" [(highValue)]="draggablemaxValue"
                  [options]="draggableoptions"></ngx-slider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with logarithmic scale</h5>
                <ngx-slider [(value)]="logscale" [options]="logscaleoptions"></ngx-slider>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with visible selection bar</h5>
                <ngx-slider [(value)]="visibleSelection" [options]="visibleBarOptions"></ngx-slider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="p-3">
                <h5 class="font-size-14 mb-3 mt-0">Slider with ticks and value</h5>
                <ngx-slider [(value)]="tickValue" [options]="tickValueoptions"></ngx-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Row -->
</div>
