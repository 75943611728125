import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Pagination} from "../../core/types/pagination.type";
import {HttpResponse} from "../../core/types/http-response.type";
import {ApiRoutes} from "../../core/routes/api.routes";
import {User} from "src/app/core/models/User";
import {Client} from "src/app/core/models/Client";
import { AuthUser } from "src/app/core/models/AuthenticatedUser";
import { catchError, map, tap } from "rxjs/operators";
import Swal from "sweetalert2";
@Injectable()
export class UsersService {

    constructor(
        private http: HttpClient
    ) { }

    index(page?: number, query?: string): Observable<Pagination<User[]>>{

        const params = {};

        if (page != null) {
            params['page'] = page.toString();
            params['limit'] = 10;
        }

        return this.http.get<Pagination<User[]>>(ApiRoutes.users.base, {
            params: params
        });
    }

    find(searchTerm: string): Observable<Pagination<User[]>> {

        searchTerm = '?searchTerm=' + searchTerm;
        const endpoint = ApiRoutes.users.finder.replace(':searchTerm', searchTerm);
        
        const params = {};

        return this.http.get<Pagination<User[]>>(endpoint, {
            params: params
        });
    }  

    indexCustomeres(page?: number, query?: string): Observable<Pagination<Client[]>>{

        const params = {};

        if (page != null) {
            params['page'] = page.toString();
            params['limit'] = 10;
        }

        return this.http.get<Pagination<Client[]>>(ApiRoutes.users.baseClients, {
            params: params
        });
    }

    findCustomers(searchTerm: string): Observable<Pagination<Client[]>> {

        searchTerm = '?searchTerm=' + searchTerm;
        const endpoint = ApiRoutes.clients.finder.replace(':searchTerm', searchTerm);
        
        const params = {};

        return this.http.get<Pagination<Client[]>>(endpoint, {
            params: params
        });
    }  

    getDictionaries(): Observable<HttpResponse<string[]>> {
        return this.http.get<HttpResponse<string[]>>(ApiRoutes.users.dictionaries);
    }

    store(body: any) {
        return this.http.post<User>(ApiRoutes.users.store, body).pipe(
            map(response => {
                return response
            }),
            catchError((err) => 
                of(
                    Swal.fire({
                        title: 'Error',
                        text: 'Ya existe un usuario registrado con el mismo correo.',
                        icon: 'error'
                      })
                )    
            )
        );
    }

    update(id: string, body: any) {
        const endpoint = ApiRoutes.users.update.replace(':id', id)

        return this.http.put<User>(endpoint, body).pipe(
            map(response => {
                return response
            }),
            catchError((err) => 
                of(
                    Swal.fire({
                        title: 'Error',
                        text: 'Ya existe un usuario registrado con el mismo correo.',
                        icon: 'error'
                      })
                )    
            )
        );
    }

    edit(id: string): Observable<User> {
        const endpoint = ApiRoutes.users.edit.replace(':id', id)

        return this.http.get<User>(endpoint)
    }

    delete(id: string): Observable<HttpResponse<void>> {
        const endpoint = ApiRoutes.users.delete.replace(':id', id)

        return this.http.delete<HttpResponse<void>>(endpoint)
    }


    userAuthenticated(): Observable<AuthUser> {
        const endpoint = ApiRoutes.auth.authenticatedUser;

        return this.http.get<AuthUser>(endpoint);
    }  
}