import { environment } from "src/environments/environment";

export const ApiRoutes = {
    auth: {
        //VALIDATED
        login: environment.endpoint + 'auth/login',
        authenticatedUser: environment.endpoint + 'auth/profile',
        password_reset: environment.endpoint + 'auth/request-password',
        change_password: environment.endpoint + 'auth/reset-password',
        logout: environment.endpoint + 'auth/logout',
    },
    dashboard: {
        path: environment.endpoint + 'dashboard'
    },
    users: {
        base: environment.endpoint + 'user',
        baseClients: environment.endpoint + 'user/customers',
        store: environment.endpoint + 'user',
        edit: environment.endpoint + 'user/:id',
        dictionaries: environment.endpoint + 'user/resources',
        delete: environment.endpoint + 'user/:id',
        update: environment.endpoint + 'user/:id',
        finder: environment.endpoint + 'user:searchTerm',
    },
    works: {
        base: environment.endpoint + 'work',
        show: environment.endpoint + 'work/:id',
        store: environment.endpoint + 'work',
        delete: environment.endpoint + 'work/:id',
        dictionaries: environment.endpoint + 'work/resources',
        edit: environment.endpoint + 'work/:id',
        update: environment.endpoint + 'work/:id',
        updateNotesAndStatus: environment.endpoint + 'work/:id/status',
        files : environment.endpoint + 'work/:id/status/:statusId/file',
        getReports: environment.endpoint + 'reports/work/status',
        finder: environment.endpoint + 'work:searchTerm',
        getCustomers: environment.endpoint + 'work/find/:searchTerm',
        getEmployees: environment.endpoint + 'work/find/:searchTerm',
        downloadFile: environment.endpoint + 'cdn/work/status/:uuid',
    },
    clients: {
        base: environment.endpoint + 'clients',
        edit: environment.endpoint + 'clients/:id/edit',
        store: environment.endpoint + 'clients',
        delete: environment.endpoint + 'clients/:id',
        update: environment.endpoint + 'clients/:id',
        dictionaries: environment.endpoint + 'clients/create',
        finder: environment.endpoint + 'user/customers:searchTerm'
    },
}