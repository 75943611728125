<div class="container-fluid">
  <app-pagetitle title="Orders" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">

          <ul class="nav nav-tabs nav-tabs-custom mb-4">
            <li class="nav-item">
                <a class="nav-link fw-bold p-3 active" href="javascript:void(0);">All Orders</a>
            </li>
            <li class="nav-item">
                <a class="nav-link p-3 fw-bold" href="javascript:void(0);">Active</a>
            </li>
            <li class="nav-item">
                <a class="nav-link p-3 fw-bold" href="javascript:void(0);">Unpaid</a>
            </li>
        </ul>
        
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Search:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-centered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th sortable="orderid" (sort)="onSort($event)">Order Id</th>
                  <th sortable="date" (sort)="onSort($event)">Date</th>
                  <th sortable="billingname" (sort)="onSort($event)">Billing Name</th>
                  <th sortable="total" (sort)="onSort($event)">Total</th>
                  <th sortable="paymentstatus" (sort)="onSort($event)">Payment Status</th>
                  <th>Invoice</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let table of tables$ | async">
                  <td>
                    <a class="text-dark fw-bold">
                      <ngb-highlight [result]="table.orderid" [term]="service.searchTerm"></ngb-highlight>
                    </a>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.billingname" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.total" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <div class="badge font-size-12" [ngClass]="{'bg-danger-subtle text-danger': table.paymentstatus === 'Chargeback',
                    'bg-success-subtle text-success':table.paymentstatus === 'Paid',
                    'bg-warning-subtle text-warning': table.paymentstatus === 'Unpaid'}">
                      <ngb-highlight [result]="table.paymentstatus" [term]="service.searchTerm"></ngb-highlight>
                    </div>
                  </td>
                  <td>
                    <button class="btn btn-light btn-rounded">Invoice <i class="mdi mdi-download ms-2"></i></button>
                  </td>
                  <td>
                    <a href="javascript:void(0);" class="me-3 text-primary" placement="top" ngbTooltip="Edit"><i
                        class="mdi mdi-pencil font-size-18"></i></a>
                    <a href="javascript:void(0);" class="text-danger" placement="top" ngbTooltip="Delete"><i
                        class="mdi mdi-trash-can font-size-18"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Showing
                {{service.startIndex}} to
                {{service.endIndex}} of {{service.totalRecords}}
                entries
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-end float-md-end pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
