<div class="card">
  <div class="card-body">
    <div class="d-flex">
      <div class="flex-1 overflow-hidden">
        <p class="text-truncate font-size-14 mb-2">{{title}}</p>
        <h4 class="mb-0">{{value}}</h4>
      </div>
      <div class="text-primary ms-auto">
        <i class="{{icon}} font-size-24"></i>
      </div>
    </div>
  </div>

  <div class="card-body border-top py-3">
    <div class="text-truncate">
      <span class="badge bg-success-subtle text-success font-size-11"><i class="mdi mdi-menu-up"> </i> 2.4% </span>
      <span class="text-muted ms-2">From previous period</span>
    </div>
  </div>
</div>
