<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/RE_Identidad_Grafica/transparent-color1_icon_transparent_background.png" alt="" height="35" width="35">
          </span>
          <span class="logo-lg">
            <img src="assets/images/RE_Identidad_Grafica/avaluos-horizontal2.png" alt="" height="35" width="150">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/RE_Identidad_Grafica/transparent-color1_icon_transparent_background.png" alt="" height="35" width="35">
          </span>
          <span class="logo-lg">
            <img src="assets/images/RE_Identidad_Grafica/avaluos-horizontal2.png" alt="" height="35" width="150">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" 
          id="page-header-user-dropdown" data-toggle="dropdown" 
          aria-haspopup="true" aria-expanded="false">
          <div class="rounded-circle d-inline">{{UserInitials}}</div> 
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          
          <a class="dropdown-item" href="javascript: void(0);" (click)="goToResetPassword()">
            <i class="ri-lock-line align-middle me-1"></i>
            Cambiar Contraseña
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
            <i class="ri-shut-down-line align-middle me-1 text-danger"></i>
            Cerrar Sesión
          </a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <!-- <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button> -->

        <button *ngIf="!isDarkMode" type="button" class="btn header-item noti-icon" (click)="setThemeMode()">
          <i class="ri-sun-line"></i>
        </button>

        <button *ngIf="isDarkMode" type="button" class="btn header-item noti-icon" (click)="setThemeMode()">
          <i class="ri-moon-fill"></i>
        </button>
      </div>
    </div>
  </div>
</header>